<template>
  <div class="RegisterBody">
    <div class="Register">
      <LoginTop middleTop="注册账号" :loShow="true" :reShow="false" />

      <LoginText
        label="账号"
        style="margin-top: 15px"
        placeholder="请输入账号"
        rule="^.{0,16}$"
        @inputChange="accountChange"
      />

      <LoginText
        label="登录密码"
        placeholder="请输入密码"
        type="password"
        rule="^.{6,16}$"
        @inputChange="passwordChange"
      />

      <LoginText
        label="确认密码"
        placeholder="请输入确认密码"
        type="password"
        @inputChange="verifyPassword"
      />

      <LoginBtn btnText="注册" @loginSubmit="userRegister" />
    </div>
  </div>
</template>

<script>
import LoginTop from "@/components/common/LoginTop.vue";
import LoginText from "@/components/common/LoginText.vue";
import LoginBtn from "@/components/common/LoginBtn.vue";
import { Toast } from "vant";
export default {
  name: "Register",
  data() {
    return {
      account: "",
      password: "",
      // 确认密码
      password1: "",
    };
  },
  components: {
    LoginTop,
    LoginText,
    LoginBtn,
  },
  methods: {
    accountChange(content) {
      console.log(content);
      this.account = content;
    },
    passwordChange(content) {
      console.log(content);
      this.password = content;
    },
    verifyPassword(content) {
      this.password1 = content;
    },
    userRegister() {
      const _this = this;

      if (this.password == this.password1 && this.account.length >= 1) {
        _this.$axios
          .post("/register", {
            username: this.account,
            password: this.password,
          })
          .then((response) => {
            console.log(response);
            const userInfo = response.data.data;
            console.log(userInfo);
            console.log("dada", this);
            Toast("注册成功！( •̀ ω •́ )✧");
            _this.$router.push("/login");
          });
      } else if (this.password.length < 6) {
        Toast("输入密码必须大于6位数哦(●'◡'●)");
      } else if (this.account.length <= 1) {
        Toast("账号长度不得少于1哦(●'◡'●)");
      } else {
        Toast("两次密码输入不一致哦，请再次尝试 ＞︿＜");
      }
    },
  },
};
</script>

<style scoped>
.Register {
/* height: 250px; */
  width: 100%;
  background-color: white;
}

.RegisterBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
</style>