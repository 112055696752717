<template>
  <div class="btnParent" >
    <van-button @click="login" class="login" type="info">{{btnText}}</van-button>
  </div>
</template>

<script>
    export default {
        props:["btnText"],
        methods:{
            login(){
                this.$emit("loginSubmit")
            }
        }
    }
</script>
    
<style>
    .btnParent{
        padding: 15px 10px;
        margin-bottom: 20px;
    }

    .login{
        
        height: 35px;
        width:90%;
        margin: 0 auto;
        justify-content: center;
        display: flex;
        border-radius: 8px;
    }
</style>