<template>
  <div>
      <div class="LoginTop">
          <h2 style="color: #909399;">{{middleTop}}</h2>

          <router-link to="/Register" v-show= "reShow" class="registerAccount">注册</router-link>
          <router-link to="/Login" v-show= "loShow" class="registerAccount">登录</router-link>
      </div>
  </div>
</template>

<script>
    export default {

        props:['middleTop','reShow', 'loShow'],
    }
</script>

<style>
    .LoginTop{
        position: relative;
        margin-top: 20px;
        height: 45px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
    }

    .registerAccount{
        position: absolute;
        right: 20px;
        top: 1;
        margin-right: 10px;
        font-size: 13px;
        color: #6b6b6b;
    }
</style>